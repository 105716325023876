import './App.css';

function Experience() {
  return (
    <div>
      <h1>Experience Page</h1>
    </div>
  );
}

export default Experience;
